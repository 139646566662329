import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { L10nProvider, L10nTranslationLoader } from "angular-l10n";
import { Observable } from "rxjs/internal/Observable";


@Injectable()
export class HttpTranslationLoader extends L10nTranslationLoader {

	private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

	constructor(@Optional() private http: HttpClient) {
		super();
	}

	public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
		let lang = language.split( "-" )[0];
		const url = `${provider.asset}${lang}.json`;
		const options = {
			headers: this.headers
		};
		return this.http.get(url, options);
	}

}
