import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor( private router: Router ) { }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        const token = sessionStorage.getItem('token');
        if ( token != undefined && token != null ) {
            return true;
        }
        this.router.navigate( ['/login'] );
        return false;
    }

}
