import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe( { name: 'dateUtcTimezone' } )
export class DateUtcTimezonePipe implements PipeTransform {

    constructor() {
    }

    transform( value: any, dateFormat?: any ): string {
		if ( value == undefined || value == null ) {
			return undefined;
		}
        if ( dateFormat == undefined || dateFormat == null ) {
            dateFormat = 'L';
        }
		return moment(value).utc(true).format(dateFormat);
    }

}
