import { Inject, Injectable, Injector } from "@angular/core";
import { L10nIntlService, L10nLoader, L10nLocale, L10nTranslationService, L10N_LOCALE } from "angular-l10n";
import { Subject, forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { AppStartupHttpService } from "app/app-startup-http.service";
import { DataSharedService } from "./app-web/app-global/services/data-shared.service";
import * as moment from "moment";


@Injectable()
export class AppStartupService {

    // Observable's unsubscribe
    private unsubscribe = new Subject<void>();

    private router: Router;

    constructor( private injector: Injector, private appStartupHttpService: AppStartupHttpService,
        private translationService: L10nTranslationService, private dataSharedService: DataSharedService, @Inject(L10N_LOCALE) public localeService: L10nLocale,
		private locale: L10nIntlService,  public l10nLoader: L10nLoader ) {
    }

    initializeApp() {
        this.setDefaultUserLocale();
        return new Promise(( resolve, reject ) => {
            this.router = this.injector.get( Router );
            this.getUserInfos().subscribe( data => {
				localStorage.setItem("user", JSON.stringify(data.user));
                // Set User locale
                this.setUserLocale( data );
                // Get App details
                this.getDetailsAfterAuthentication().subscribe(
                    response => resolve( true ),
                    error => reject( false )
                );
            }, error => resolve( true ) );
        } );
    }


    /********************************************
     * ******************************************
     * User
     * ******************************************
     ********************************************/

    /**
     * get the User informations
     */
    getUserInfos() {
        return this.appStartupHttpService.getUserInfos();
    }

    /**
     * set the default locale of User
     */
    setDefaultUserLocale() {
        let userLocale: string;
        let userLocaleLocalStorage = localStorage.getItem( 'defaultLocale' );
        if ( userLocaleLocalStorage != undefined && userLocaleLocalStorage != null ) {
            userLocale = userLocaleLocalStorage;
        } else {
            userLocale = navigator.language;
        }
		this.translationService.setLocale(undefined);
        this.translationService.setLocale( { language: userLocale, dateLanguage: userLocale, numberLanguage: userLocale, currency: undefined, timeZone: 'UTC' } );
    }

    /**
     * set locale of User
     */
    setUserLocale( data: any ) {
        let userLocale: string = data.locale;
		localStorage.setItem( 'defaultLocale', userLocale );
		this.translationService.setLocale(undefined);
        this.translationService.setLocale( { language: userLocale, dateLanguage: userLocale, numberLanguage: userLocale, currency: undefined, timeZone: 'UTC' } );
		moment.locale(userLocale);
    }


    /********************************************
     * ******************************************
     * After Authentication
     * ******************************************
     ********************************************/

    getDetailsAfterAuthentication() {
        const observable = forkJoin( this.getAuthorityList() );
        observable.pipe( takeUntil( this.unsubscribe ) ).subscribe(( [authorityList] ) => {
            // Get Authority List of User
            if ( !Array.isArray( authorityList ) ) {
                authorityList = [];
            }
            this.dataSharedService.authorityList = authorityList;
        } );
        return observable;
    }

    /**
     * get the list of authorities
     */
    getAuthorityList() {
        return this.appStartupHttpService.getAuthorityList();
    }

}
