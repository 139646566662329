import { Injectable } from "@angular/core";
import { MedicalOffice } from "app/app-web/app-model/dto/medical-office.model";


@Injectable()
export class DataSharedService {
	
	// Authoriy
	public authorityList: string[];
	
	// Medical office
	public medicalOfficeList: MedicalOffice[];


    constructor() {
    }

}
