import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators/catchError";
import { throwError, Observable } from "rxjs";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslationPipe } from "app/app-web/app-core/translation/translation.pipe";


@Injectable()
export class ErrorHttpRequestInterceptor implements HttpInterceptor {

    constructor( private router: Router, private messageService: MessageService, private translationPipe: TranslationPipe ) { }

    intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle( request ).pipe(
            catchError(( error, caught ) => {
                if ( error.status === 401 ) {
                    sessionStorage.removeItem( 'token' );
                    this.router.navigate( ['/login'] );
                }
				if ( error.status === 0 && error.error instanceof ProgressEvent) {
					let translatedTitle = this.translationPipe.transform( "lblWarn" );
        			let translatedMessage = this.translationPipe.transform( "lblWarnMsgInternetConnection" );
					this.messageService.add( { severity: "warn", summary: translatedTitle, detail: translatedMessage, life: 5000 } );
				}
                return throwError( error );
            } ) ) as any;
    }

}
