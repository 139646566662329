import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor( private router: Router ) { }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        const token = sessionStorage.getItem('token');
        if ( token == undefined && token == null ) {
            return true;
        }
        this.router.navigate( ['/'] );
        return false;
    }

}
