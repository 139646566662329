import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";


@Injectable()
export class LoadingService {

    private count: number;

    constructor( private ngxSpinnerService: NgxSpinnerService ) {
        this.count = 0;
    }

    /**
     * show the loading spinner
     */
    showLoading(): void {
        this.count++;
        this.ngxSpinnerService.show();
    }

    /**
     * hide the loading spinner
     */
    hideLoading(): void {
        this.count--;
        if ( this.count <= 0 ) {
            this.ngxSpinnerService.hide();
        }
    }

}
