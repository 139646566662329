import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserModule } from "@angular/platform-browser";
import { AppStartupService } from "app/app-startup.service";
import { AppRoutingModule } from "app/config/routing/app-routing.module";
import { AppInternationalizationModule } from "app/config/internationalization/app-internationalization.module";
import { AppStartupHttpService } from "app/app-startup-http.service";
import { TranslationModule } from './app-web/app-core/translation/translation.module';
import { DataSharedModule } from './app-web/app-global/services/data-shared.module';
import { MessageService } from 'primeng/api';
import { AppHttpModule } from './config/http/app-http.module';


export function onAppInit( appStartupService: AppStartupService ) {
    return () => appStartupService.initializeApp();
}

@NgModule( {
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
		AppHttpModule,
        AppRoutingModule,
        AppInternationalizationModule,
		TranslationModule,
		DataSharedModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AppStartupHttpService,
        AppStartupService,
		MessageService,
        { provide: APP_INITIALIZER, useFactory: onAppInit, multi: true, deps: [AppStartupService] }
    ],
    bootstrap: [AppComponent]
} )
export class AppModule { }
