import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "app/config/guards/auth.guard";
import { AppComponent } from "app/app.component";
import { LoginGuard } from "app/config/guards/login.guard";


const appRoutes: Routes = [
    {
        path: '', component: AppComponent,
        children: [
            { path: 'login', loadChildren: () => import(`app/login/login.module`).then(m => m.LoginModule), canActivate: [LoginGuard] },
            { path: '', loadChildren: () => import(`app/app-web/app-web.module`).then(m => m.AppWebModule), canActivate: [AuthGuard] },
            { path: '**', redirectTo: '' }
        ]
    },
];

@NgModule( {
    imports: [
        RouterModule.forRoot( appRoutes, { useHash: true } )
    ],
    exports: [RouterModule],
    providers: [AuthGuard, LoginGuard]
} )
export class AppRoutingModule { }

function newFunction() {
    return import('app/login/login.module');
}
