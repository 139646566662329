import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { Moment } from "moment";
import { DateUtcTimezonePipe } from "app/app-web/app-core/date/date-utc-timezone.pipe";


@Injectable()
export class DateService {

    constructor( private dateUtcTimezonePipe: DateUtcTimezonePipe ) {
    }

    /**
     * get the Utc Ms from Moment
     */
    getUtcMsFromMoment( dateMoment: Moment ): number {
        if ( dateMoment == undefined || dateMoment == null ) {
            return null;
        }
        return dateMoment.utc( false ).valueOf();
    }

    /**
     * get the Moment from Utc Ms
     */
    getMomentFromUtcMs( dateMs: number ): Moment {
        if ( dateMs == undefined || dateMs == null ) {
            return null;
        }
        return moment( dateMs ).utc();
    }
    
    /**
     * get the string UTC date
     */
    getStringUtcDate( dateMs: number, format?: string ): string {
        if ( dateMs == undefined || dateMs == null ) {
            return null;
        }
        return this.dateUtcTimezonePipe.transform( dateMs, format );
    }

	/**
     * get the Utc Ms from String
     */
    getUtcMsFromString( dateString: string, dateFormat?: string ): number {
        if ( dateString == undefined || dateString == null ) {
            return null;
        }
		if ( dateFormat == undefined || dateFormat == null ) {
			dateFormat = moment.localeData().longDateFormat('L');
		}
		let separator = (dateFormat.indexOf('/') > -1 ? "/" : (dateFormat.indexOf('-') > -1 ? '-' : '.'));
		let dateArray = dateString.split(separator);
		let firstElement = Number(dateArray[0]);
		let secondElement = Number(dateArray[1]);
		let thirdElement = Number(dateArray[2]);
		let result;
		if ( isNaN(firstElement) === false && isNaN(secondElement) === false && isNaN(thirdElement) === false ) {
			result = moment(dateString, dateFormat).utc(true).valueOf();
		}
		return result;
	}

}
