import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from "app/config/interceptors/http-request.interceptor";
import { ErrorHttpRequestInterceptor } from "app/config/interceptors/error-http-request.interceptor";
import { LoadingService } from "app/config/http/loading.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { NotificationService } from 'app/app-web/app-core/notification/notification.service';


@NgModule( {
    imports: [
        HttpClientModule,
        NgxSpinnerModule
    ],
    exports: [
        HttpClientModule,
        NgxSpinnerModule
    ],
    providers: [
        LoadingService,
		NotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpRequestInterceptor, multi: true }
    ]
} )
export class AppHttpModule { }
