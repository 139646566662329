import { Inject, Pipe, PipeTransform } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from "angular-l10n";

@Pipe( { name: 'translation' } )
export class TranslationPipe implements PipeTransform {
    
    constructor( private translationService: L10nTranslationService, @Inject(L10N_LOCALE) public localeService: L10nLocale ) {
    }

    transform( value: any ): string {
        return this.translationService.translate( value, undefined, this.localeService.language );
    }

}
