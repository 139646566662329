import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { TranslationPipe } from 'app/app-web/app-core/translation/translation.pipe';


@Injectable()
export class NotificationService {

    constructor( private messageService: MessageService, private translationPipe: TranslationPipe ) {
    }

    /**
     * show an error notification
     */
    showSuccessNotification( title: string, message: string ) {
        this.showNotification( "success", title, message );
    }

    /**
     * show an warn notification
     */
    showWarnNotification( title: string, message: string ) {
        this.showNotification( "warn", title, message );
    }

    /**
     * show an error notification
     */
    showErrorNotification( title: string, message: string ) {
        this.showNotification( "error", title, message );
    }

    /**
     * show a notification
     */
    showNotification( type: string, title: string, message: string ) {
        let translatedTitle = this.translationPipe.transform( title );
        let translatedMessage = this.translationPipe.transform( message );
        this.messageService.add( { severity: type, summary: translatedTitle, detail: translatedMessage, life: 5000 } );
    }

}
