import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from "environments/environment";
import { L10nConfig, L10nDatePipe, L10nIntlModule, L10nLoader, L10nNumberPipe, L10nTranslationModule, L10nValidationModule } from 'angular-l10n';
import { HttpTranslationLoader } from './http-translation-loader.service';


const l10nConfig: L10nConfig = {
	format: 'language-region',
	providers: [{ name: 'app', asset: environment.assertsContextPath + '/assets/language/lang_' }],
	cache: true,
	keySeparator: undefined,
	defaultLocale: {
		language: undefined,
		timeZone: 'UTC'
	},
	schema: []
};

export function initL10n( l10nLoader: L10nLoader ): Function {
    return () => l10nLoader.init();
}

@NgModule( {
    imports: [
        CommonModule,
        L10nTranslationModule.forRoot( l10nConfig, { translationLoader: HttpTranslationLoader } ),
		L10nValidationModule.forRoot(),
        L10nIntlModule
    ],
    exports: [
        L10nTranslationModule,
		L10nValidationModule,
        L10nIntlModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initL10n, deps: [L10nLoader], multi: true },
		L10nNumberPipe, L10nDatePipe
    ]
} )
export class AppInternationalizationModule {

    constructor() {
    }

}
